@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

$dot-size: 1.5rem;

.timeline {
  @media #{$mq-md} {
    min-height: 45vh;
    padding: .5rem 2rem;
  }
  min-height: 50vh;
  background: $black;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 1.5rem;

  &--tabmenu {
    @media #{$mq-md} {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      margin: 1rem 0;
    }

    display: flex;
    flex-wrap: wrap;

  }
  
  p {
    @media #{$mq-md} {
      margin-bottom: 1rem;
      margin-right: 1.5rem;
    }
    
    @extend %body-lg;
    color: $gray-700;
    margin: 0.5rem .33rem;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .tab--selected {
    color: $gray-200;
  }
}  

.timeline-content  {
  @media #{$mq-md} {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
}

.card { 
    @media #{$mq-lg} {
      min-width: auto;
    }
    @media #{$mq-md} {
      flex-direction: row;
      justify-content: flex-start;
    }
    opacity: 0;
    display: none;
    flex-direction: column;
    transition-duration: 1s ease-in-out;
    width: 100%;
  
    &__visible { 
    opacity: 1;
    transition-duration: 1s;
    display: inline-block;
    }
  
  
    .line {
      @media #{$mq-md} {
        display: inline-block;
      }
      width: 100%;
      height: 2px;
      display: none;
      position: relative;
      background: $gray-800;
      top: 15px;
      z-index: 0;
    }
  
    .dot {
      @media #{$mq-md} {
        width: $dot-size;
        height: $dot-size;
        border: 0.06rem solid $gray-200;
        border-radius: calc(#{$dot-size} / 2);
        cursor: pointer;
        display: inline-block;
      }
      
      position: relative;
      z-index: 5;
      display: none;
      transition: background-color 300ms;
      background-color: $primary;
    
      &--selected {
        cursor: default;
      }
    
      &:hover {
        background-color: $gray-800;
      }
    }
  
    .card-text {
      @media #{$mq-lg} {
        max-width: 300px;
      }
      @extend %body;
      font-size: 15px;
      line-height: 22px;
      color: $gray-200;
      max-width: 200px;
      margin: 1rem 1rem 0 0;
    }
  }
