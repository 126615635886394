@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.audits {
  @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 5rem 0;
    margin: 2rem $margin-lg;
  }
  @media #{$mq-lg} {
    padding: 2rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 0 $margin-sm 0 $margin-sm;

  &--container {
    @media #{$mq-md} {
    padding: 5rem 0;
    margin-top: 0vh;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
  
  &--header {
    @media #{$mq-sm} {
      align-items: flex-start;
      padding: 3rem 0;
    }
    @media #{$mq-lg} {
      padding: 5rem 0
    }
    display: flex;
    flex-direction: column;
    margin: 5vh 0;
  }
  
  .title {
    @extend %h1;
    text-align: left;
    color: $black;
    margin-block-end: 0;
    font-weight: bolder;
  }

  }
  
  &--description { 
    @media #{$mq-md} {
      width: 60%
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
  

  }
  
  h4 {
    @extend %body-lg;
    margin: 1rem 0;
  }

  .audits-button { 
    @extend %link;
    font-family: 'NeueMontrealMedium';
    font-weight: 600;
    position: relative;
    padding: .6rem 1rem;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition-duration: 0.15s;
    overflow: hidden;
    
    background-color: $gray-900;
    text-decoration: none;
    color: $gray-100;

    &:hover, &:active {
      filter: brightness(0.9);
    }

    &__primary {
      background-color: $primary;
      text-decoration: none;
      color: $gray-900;
    }

}
}
